import { IEmailQuoteProps } from '../interfaces/emailQuote.interface';

export const getSelectedSections = ({ sections, selection, features, totalPrice }: IEmailQuoteProps) => {
  const temp: any = {};
  Object.keys(selection).forEach((section: any) => {
    temp[section] = sections[section];

    temp[section].features = temp[section].features.map((feat: any) => {
      const selectedFeature = selection[section].features.find(
        (featId) => featId === feat.id
      );

      feat.selected = selectedFeature ? true : false;

      return feat;
    });
  });
  return temp;
};