import { Routes, Route } from 'react-router-dom';
import NotFoundPage from '../pages/404';
import App from './App';
import MaintenancePage from '../pages/MaintenancePage';

const Main = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/maintenance" element={<MaintenancePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default Main;
