import { FC, useState, useEffect } from 'react';
import { ISection } from '../interfaces/sections.interface';
import { IFeature } from '../interfaces/features.interface';
import { ISelection } from '../interfaces/selection.interface';
import SelectedSection from './SelectedSection';
import { IProjectResource } from '../interfaces/project-resource.interface';
import { formatPrice, getTimeZone } from '../utils/helpers';
import { getSelectedSections } from '../utils/sections';
import { IEmailQuoteProps } from './QuoteTemplate';
import EmailQuoteForm from './EmailQuoteForm';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';

export interface IBreakdownProps {
  sections: { [key: string]: ISection };
  features: { [key: string]: IFeature };
  selection: { [key: string]: ISelection };
  projectResources: { [key: string]: IProjectResource };
  baseDays: number;
  addToSelection: any;
  updateCalc: any;
  resetCalc: any;
}

interface IResource {
  name: string;
  cost: number;
}

const Breakdown: FC<IBreakdownProps> = (props) => {
  const {
    sections,
    features,
    selection,
    baseDays,
    projectResources,
    addToSelection,
    updateCalc,
    resetCalc,
  } = props;

  const [showCalculation, setShowCalculation] = useState<boolean>(false);
  const [emailFormVisible, setEmailFormVisible] = useState<boolean>(false);
  const [canShowEmailForm, setCanShowEmailForm] = useState<boolean>(false);

  const selectionIds = Object.keys(selection);
  const daysAccumulator: number[] = [];
  const resourceIds = Object.keys(projectResources);
  const resourceTable: Partial<IResource>[] = [];

  selectionIds.forEach((id) => {
    selection[id].features.forEach((feature) => {
      if (features[feature]) {
        if (!features[feature].design && features[feature].days) {
          daysAccumulator.push(features[feature].days);
          return;
        }
        daysAccumulator.push((props.baseDays * features[feature].days) / 100);
      } else {
        console.warn(`Feature with id "${feature}" not found.`);
      }
      daysAccumulator.push((baseDays * features[feature].days) / 100);
    });
  });

  let days = 0;
  selectionIds.forEach((id) => {
    selection[id].features.forEach((feature) => {
      if (features[feature] && !features[feature].design) {
        days += features[feature].days;
      }
    });
  });

  resourceIds.forEach((key) => {
    const resource: Partial<IResource> = {
      name: projectResources[key].role,
      cost: 0,
    };

    selectionIds.forEach((id) => {
      selection[id].features.forEach((feature) => {
        if (features[feature].design && projectResources[key].design) {
          const daysAvg = (baseDays * features[feature].days) / 100;
          resource.cost = daysAvg * projectResources[key].rate;
        }

        if (!features[feature].design && !projectResources[key].design) {
          projectResources[key].allocation?.forEach((allocation) => {
            if (allocation.sizeOfApp.id === feature) {
              if (allocation.resourceCount) {
                const devBase = allocation.resourceCount * baseDays;
                const devExtra = days - baseDays;
                resource.cost =
                  (devBase + devExtra) * projectResources[key].rate * 8;
              }

              if (allocation.percentage) {
                resource.cost =
                  ((days * allocation.percentage) / 100) *
                  projectResources[key].rate *
                  8;
              }
            }
          });
        }
      });
    });

    resourceTable.push(resource);
  });

  const totalPrice = resourceTable.reduce((prevTotal, currValue) => {
    if (currValue.cost) {
      return prevTotal + currValue.cost;
    }
    return prevTotal;
  }, 0);

  const sectionsObj = { sections, selection, features, totalPrice };

  useEffect(() => {
    setCanShowEmailForm(totalPrice > 0);
  }, [totalPrice]);

  const handleShowEmailForm = () => {
    setEmailFormVisible(!emailFormVisible);
  };

  const allSelectedSections = getSelectedSections(
    sectionsObj as IEmailQuoteProps
  );

  let quoteData: any = {
    sections: [],
    totalPrice: totalPrice,
    totalDays: days,
  };

  for (let section of Object.values(allSelectedSections) as any) {
    const allSelectedFeatures: any = [];

    for (let feature of section.features) {
      if (!feature.selected) continue;

      const selectedFeature = {
        ...feature,
        days: features[feature.id].days,
      };

      allSelectedFeatures.push(selectedFeature);
    }

    const featuresObject = {
      id: section.id,
      index: section.order,
      features: allSelectedFeatures,
      name: section.name,
    };

    quoteData.sections.push(featuresObject);
  }

  return (
    <Box
      paddingTop="1rem"
      textAlign="center"
      backgroundColor={`${totalPrice > 0 ? 'rgba(35, 31, 32, 0.05)' : ''}`}
    >
      {days > 1 && (
        <Button
          size="2xl"
          outline="2px solid transparent"
          outlineOffset="2px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginX="auto"
          marginY="2rem"
          border="1px solid #231f20"
          borderRadius="0"
          lineHeight="1.75rem"
          padding="1rem"
          backgroundColor="transparent"
          fontWeight="400"
          _hover={{ cursor: 'pointer', backgroundColor: 'transparent' }}
          _focus={{ cursor: 'pointer', backgroundColor: 'transparent' }}
          _active={{ cursor: 'pointer', backgroundColor: 'transparent' }}
          onClick={() => setShowCalculation(!showCalculation)}
        >
          <Text as="span" marginRight="0.5rem">{`Click to ${
            showCalculation ? 'Hide' : 'Show'
          } Calculations`}</Text>

          {showCalculation ? (
            <Text as="span" height="1.25rem" width="1.25rem">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m4.5 15.75 7.5-7.5 7.5 7.5"
                />
              </svg>
            </Text>
          ) : (
            <Text as="span" height="1.25rem" width="1.25rem">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </Text>
          )}
        </Button>
      )}
      {showCalculation && (
        <Box
          paddingY="10px"
          maxWidth="1200px"
          marginX="auto"
          backgroundColor="white"
        >
          <SelectedSection
            projectResources={projectResources}
            sections={sections}
            features={features}
            selection={selection}
            baseDays={baseDays}
            totalPrice={totalPrice}
            addToSelection={addToSelection}
            updateCalc={updateCalc}
            resetCalc={resetCalc}
          />
        </Box>
      )}
      {totalPrice > 0 && (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          paddingTop="4rem"
          paddingBottom="1rem"
          textAlign="center"
        >
          <Heading as="h2" fontWeight="400" textTransform="none">
            Total Cost:
          </Heading>
          <Heading as="h2">
            {formatPrice(totalPrice)}

            {getTimeZone() && (
              <Text
                as="span"
                fontWeight="400"
                textTransform="none"
                paddingLeft={'5px'}
              >
                (excl. VAT)
              </Text>
            )}
          </Heading>
        </Flex>
      )}
      <Flex
        flexDirection="column"
        alignItems="center"
        paddingTop="4rem"
        paddingBottom="1rem"
      >
        {totalPrice > 0 && (
          <Button
            hidden={emailFormVisible}
            size="2xl"
            outline="2px solid transparent"
            outlineOffset="2px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginX="auto"
            marginY="2rem"
            border="1px solid #231f20"
            borderRadius="0"
            lineHeight="1.75rem"
            padding="1rem"
            backgroundColor="transparent"
            fontWeight="400"
            _hover={{ cursor: 'pointer', backgroundColor: 'transparent' }}
            _focus={{ cursor: 'pointer', backgroundColor: 'transparent' }}
            _active={{ cursor: 'pointer', backgroundColor: 'transparent' }}
            disabled={!canShowEmailForm}
            onClick={handleShowEmailForm}
          >
            Email Quote
          </Button>
        )}

        <EmailQuoteForm
          quoteData={quoteData}
          emailFormVisible={emailFormVisible}
          setEmailFormVisible={setEmailFormVisible}
          setEmailSent={
            setEmailFormVisible as React.Dispatch<
              React.SetStateAction<boolean | undefined>
            >
          } // Type cast to align types
        />
      </Flex>
      <Box position="relative">
        <Text
          as="p"
          textAlign="center"
          color="#000"
          fontSize="13px"
          fontWeight="500"
        >
          Responsive 2023 © All Rights Reserved
        </Text>
        <Box
          textAlign="center"
          color="#000"
          fontSize="13px"
          fontWeight="500"
          paddingBottom="20px"
        >
          <Text
            as="a"
            href="https://goresponsive.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            _hover={{ textDecoration: 'underline' }}
          >
            Privacy Policy
          </Text>
          {' | '}
          <Text
            as="a"
            href="https://goresponsive.com/assets/pdf/paia-manual.pdf"
            target="_blank"
            rel="noopener noreferrer"
            _hover={{ textDecoration: 'underline' }}
          >
            PAIA
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Breakdown;
