import {
  getFirestore,
  collection,
  getDocs,
  where,
  query,
  and,
  getDoc,
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentData,
} from 'firebase/firestore';
import { ref, getDownloadURL, getStorage } from 'firebase/storage';
import { IResource } from '../interfaces/resource.interface';
import { ISection } from '../interfaces/sections.interface';
import { IFeature } from '../interfaces/features.interface';
import { IProjectResource } from '../interfaces/project-resource.interface';

const db = getFirestore();
const storage = getStorage();

type DataTypes = 'section' | 'resource' | 'feature';

export const fetchAllContent = async () => {
  try {
    const contentRef = collection(db, 'fl_content');
    const collectionQuery = query(
      contentRef,
      and(where('_fl_meta_.env', '==', 'production'))
    );

    const response = await getDocs(collectionQuery);

    if (!response) return;

    const data = await Promise.all(
      response.docs.map(async (doc: QueryDocumentSnapshot) => {
        const item = doc.data();

        if (item._fl_meta_.schema === 'costingSection') {
          item.features = await Promise.all(
            item.features.map(async (featureRef: DocumentReference) => {
              const featureDoc = await getDoc(featureRef);
              return featureDoc.exists() ? featureDoc.data() : null;
            })
          );
        }

        return item;
      })
    );

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSections = (allContent: any[]): { [key: string]: ISection } => {
  const sections = allContent.filter(
    (c) => c._fl_meta_.schema === 'costingSection'
  );

  return convertToObject(sections.reverse(), 'section');
};

export const getFeatures = (allContent: any[]): { [key: string]: IFeature } => {
  const features = allContent.filter((c) => c._fl_meta_.schema === 'feature');

  return convertToObject(features, 'feature');
};

export const getProjectResources = (
  allContent: any[]
): { [key: string]: IProjectResource } => {
  const resources = allContent.filter(
    (c) => c._fl_meta_.schema === 'resources'
  );

  return convertToObject(resources, 'resource');
};

export const convertToObject = (data: any[], type: DataTypes) => {
  const convertedContentMap: any = {};

  for (let item of data) {
    if (!item) continue;

    convertedContentMap[item.id] = item;

    if (type === 'feature' || type === 'section') {
      convertedContentMap[item.id].description = item.featureDescription;
    }
  }

  return convertedContentMap;
};

interface IconData {
  file: string;
  contentType: string;
  id: string;
}

interface Feature {
  icon: (DocumentReference<DocumentData> | IResource)[];
  selectedIcon: (DocumentReference<DocumentData> | IResource)[];
}

const isDocumentReference = (
  obj: any
): obj is DocumentReference<DocumentData> => {
  return obj && typeof obj === 'object' && 'id' in obj && 'path' in obj;
};

export const getFeatureIcons = async (
  feature: Feature,
  path: string = 'flamelink/media'
) => {
  try {
    if (!Array.isArray(feature.icon) || !Array.isArray(feature.selectedIcon)) {
      throw new Error('Expected arrays for icon and selectedIcon properties');
    }

    if (
      !isDocumentReference(feature.icon[0]) ||
      !isDocumentReference(feature.selectedIcon[0])
    ) {
      throw new Error(
        'Expected DocumentReference, but found custom object or invalid reference'
      );
    }

    const iconDoc = await getDoc(feature.icon[0]);
    const selectedIconDoc = await getDoc(feature.selectedIcon[0]);

    if (!iconDoc.exists() || !selectedIconDoc.exists()) {
      throw new Error('One or more icon documents do not exist');
    }

    const iconDocData = iconDoc.data() as IconData;
    const selectedIconDocData = selectedIconDoc.data() as IconData;

    const iconFileRef = ref(storage, `${path}/${iconDocData.file}`);
    const downloadUrl = await getDownloadURL(iconFileRef);

    const selectedIconFileRef = ref(
      storage,
      `${path}/${selectedIconDocData.file}`
    );
    const selectedIconDownloadUrl = await getDownloadURL(selectedIconFileRef);

    const unselectedIcon: IResource = {
      url: downloadUrl,
      contentType: iconDocData.contentType,
      id: iconDocData.id,
    };

    const selectedIcon: IResource = {
      url: selectedIconDownloadUrl,
      contentType: selectedIconDocData.contentType,
      id: selectedIconDocData.id,
    };

    feature.icon = [unselectedIcon];
    feature.selectedIcon = [selectedIcon];

    return feature;
  } catch (error) {
    console.error('Error fetching feature icons:', error);
    throw error;
  }
};
