import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { sendEmail } from '../api';
import LoadingSpinner from './LoadingSpinner';
import * as yup from 'yup';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select, Input } from '@chakra-ui/react';
import { ISection } from '../interfaces/sections.interface';

const formSchema = yup.object({
  name: yup.string().required('Your name is required'),
  surname: yup.string().required('Your surname is required'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Your email address is required'),
  number: yup.string(),
  company: yup.string().max(250),
  companySize: yup.string(),
});

type FormData = yup.InferType<typeof formSchema>;
interface EmailFormQuote {
  quoteData: any;
  emailFormVisible: boolean | undefined;
  setEmailFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setEmailSent: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const companySizeValues: string[] = [
  '1-10',
  '11-50',
  '51-250',
  '251-1K',
  '1K-5K',
  '5K+',
];

const EmailQuoteForm: React.FC<EmailFormQuote> = ({
  quoteData,
  emailFormVisible,
  setEmailFormVisible,
  setEmailSent,
}) => {
  const form = useForm<FormData>({
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      number: '',
      company: '',
      companySize: '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(formSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = form;

  const [hasInteracted, setHasInteracted] = useState(false);
  const [emailSentStatus, setEmailSentStatus] = useState<boolean | undefined>(
    undefined
  );
  const [isManualSubmitting, setIsManualSubmitting] = useState(false);

  const [cancelEmailSend, setCancelEmailSend] = useState<boolean | undefined>(
    undefined
  );

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleInteraction = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!event) return;

    const value = event.target.value;

    if (value !== '') {
      setHasInteracted(true);
      return;
    }

    setHasInteracted(false);

    return;
  };

  const handleOnSubmit = (data: FormData) => {
    setIsManualSubmitting(true);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      onSubmit(data);
    }, 3000);

    if (cancelEmailSend) {
      setIsManualSubmitting(false);
      setCancelEmailSend(false);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  useEffect(() => {
    quoteData.sections.forEach((item: ISection) => {
      item.features.forEach((feature: any) => delete feature._fl_meta_);
    });
  }, [quoteData]);

  const onSubmit = async (data: FormData) => {
    setIsManualSubmitting(true);

    if (cancelEmailSend) return;
    const formValues = {
      quoteData,
      to: data.email,
      ...data,
    };

    const res = await sendEmail(formValues);

    if (res.data === 'success') {
      setEmailSentStatus(true);
      setEmailFormVisible(false);
      reset();
    } else {
      setEmailSentStatus(false);
    }

    setIsManualSubmitting(false);

    setTimeout(() => setEmailSentStatus(undefined), 4000);
  };

  const handleEmailCancel = () => {
    setIsManualSubmitting(false);
    setCancelEmailSend(undefined);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = null;
  };

  const toastMessage = (input?: string) => {
    let message: string | undefined = '';

    if (emailSentStatus !== undefined && !input) {
      message = emailSentStatus
        ? 'Your email has been sent!'
        : 'Your email could not be sent!';
    } else {
      message = errors[input as keyof typeof errors]?.message;
    }

    return (
      <div
        className={
          emailSentStatus !== undefined && !input
            ? 'email-success'
            : 'email-error'
        }
      >
        {message}
      </div>
    );
  };

  return (
    <div className="email-form-wrapper">
      {emailSentStatus && (
        <div className="toast-message-container">
          <div className="toast-success flex justify-center items-center">
            <span>{toastMessage()}</span>
          </div>
        </div>
      )}

      {emailFormVisible && (
        <div className="email-form-container">
          <div className="email-quote-form-header">
            <span className="email-quote-form-header-description">
              Please enter your information and we will email you a quote
            </span>
            <span
              className="icon email-close-button"
              onClick={() => setEmailFormVisible(false)}
            >
              <FontAwesomeIcon
                icon={faXmark}
                className="email-close-button-icon"
              />
            </span>
          </div>

          <div className="email-quote-form-wrapper">
            <form action="">
              <div className="email-quote-form">
                <div className="input-wrapper">
                  <Input
                    {...register('name')}
                    height="60px"
                    width="100%"
                    bg="#fff"
                    borderRadius={0}
                    border={'1px solid black'}
                    type="text"
                    name="name"
                    placeholder="First Name *"
                    required
                  />
                  {toastMessage('name')}
                </div>
                <div className="input-wrapper">
                  <Input
                    {...register('surname')}
                    height="60px"
                    width="100%"
                    bg="#fff"
                    borderRadius={0}
                    border={'1px solid black'}
                    type="text"
                    name="surname"
                    placeholder="Surname *"
                    required
                  />
                  {toastMessage('surname')}
                </div>
                <div className="input-wrapper">
                  <Input
                    {...register('email')}
                    height="60px"
                    width="100%"
                    bg="#fff"
                    borderRadius={0}
                    border={'1px solid black'}
                    type="text"
                    name="email"
                    placeholder="Email Address *"
                  />
                  {toastMessage('email')}
                </div>
                <div className="input-wrapper">
                  <Input
                    {...register('number')}
                    height="60px"
                    width="100%"
                    bg="#fff"
                    borderRadius={0}
                    border={'1px solid black'}
                    name="number"
                    type="tel"
                    placeholder="Mobile Number"
                  />
                  {toastMessage('number')}
                </div>
                <div className="input-wrapper">
                  <Input
                    {...register('company', { maxLength: 250 })}
                    height="60px"
                    width="100%"
                    bg="#fff"
                    borderRadius={0}
                    border={'1px solid black'}
                    type="text"
                    name="company"
                    placeholder="Company"
                    maxLength={250}
                  />
                </div>
                <div>
                  <Select
                    {...register('companySize')}
                    height="60px"
                    width={{ base: '90vw', md: '80vw', lg: '450px' }}
                    bg="#fff"
                    borderRadius={0}
                    onChange={handleInteraction}
                    color={hasInteracted ? 'black' : '#999'}
                    border={'1px solid black'}
                    name="companySize"
                    defaultValue="Company Size"
                  >
                    <option value="">Company Size</option>
                    {companySizeValues.map((size) => (
                      <option value={size} key={size}>
                        {size}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              {isManualSubmitting ? (
                <>
                  <LoadingSpinner />
                  <button
                    className="flex content-center btn font-body mx-auto leading-7 p-4 m-8 email-submit-button"
                    type="button"
                    onClick={handleEmailCancel}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  type="submit"
                  onClick={handleSubmit(handleOnSubmit)}
                  className="flex content-center btn font-body mx-auto leading-7 p-4 m-8 email-submit-button"
                  disabled={isManualSubmitting}
                >
                  Submit
                </button>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailQuoteForm;
