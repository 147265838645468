import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions'

import flamelink from 'flamelink/app';
// Add additional modules that you want to use
import 'flamelink/content';
import 'flamelink/storage';
// import 'flamelink/settings'
// import 'flamelink/navigation'
// import 'flamelink/users'

const flamelinkFBConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

const firebaseApp = firebase.initializeApp(flamelinkFBConfig);

export const functions = () => firebaseApp?.functions();

const user = firebase.auth().currentUser;

if (!user) {
  firebase.auth().signInAnonymously();
}

const flamelinkApp = flamelink({
  firebaseApp,
  dbType: 'cf',
  env: 'production',
  locale: 'en-US',
});

export default flamelinkApp;
