import { FC } from 'react';
import { Box } from '@chakra-ui/react';

interface ICheckboxProps {
  checked: boolean;
  handleClick: any;
}
const CheckBox: FC<ICheckboxProps> = ({ checked, handleClick }) => {
  if (checked) {
    return (
      <Box
        fontSize="0.75rem"
        lineHeight="1.625rem"
        paddingRight="0.25rem"
        marginRight="0.5rem"
        width="1rem"
        height="1rem"
        borderRadius="9999px"
        cursor="pointer"
        backgroundColor="#295494"
        onClick={handleClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 20 22"
        >
          <g fill="none" fillRule="evenodd" transform="translate(1 2)">
            <circle cx="9" cy="9" r="9" fill="#ffe713" stroke="#295494" />
            <path
              stroke="#295494"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 9.215L6.323 13 14 5"
            />
          </g>
        </svg>
      </Box>
    );
  }

  return (
    <Box
      marginRight="0.5rem"
      width="1rem"
      height="1rem"
      borderRadius="9999px"
      cursor="pointer"
      borderWidth="1px"
      onClick={handleClick}
    />
  );
};

export default CheckBox;
