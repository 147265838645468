import { Flex, Heading, Image, Text } from '@chakra-ui/react';

const MaintenancePage = () => {
  return (
    <div>
      <Flex
        height="64px"
        paddingX="24px"
        justifyContent="space-between"
        alignItems="center"
      >
        <a href="https://goresponsive.com/">
          <Image
            src="responsive-logo.svg"
            alt="Responsive Logo"
            paddingTop="0.5rem"
            opacity="1"
            transition="opacity 0.3s ease-in-out"
            _hover={{ opacity: '0.7' }}
          />
        </a>
      </Flex>

      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="90vh"
      >
        <Flex justifyContent="center" alignItems="center" width="600px">
          <Image src="/Undercons.svg" alt="Yellow splash" width="600px" />
        </Flex>

        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxWidth="600px"
          gap="30px"
          className="titles-Appdream"
        >
          <Heading
            as="h1"
            fontSize="46px"
            fontWeight="500"
            textTransform="none"
            textAlign="center"
            color="#295494"
          >
            <b>Maintenance</b> in progress!
          </Heading>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Text as="p" textAlign="center" color="#295494" fontSize="24px">
              We are currently in a maintenance phase.
            </Text>
            <Text as="p" textAlign="center" color="#295494" fontSize="24px">
              The website will be available soon!
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};
export default MaintenancePage;
