import { BrowserRouter } from 'react-router-dom';
import './assets/main.css';
import './assets/recost.css';
import Main from './components/Main';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: `Montserrat`,
    body: `Montserrat`,
  },
})

const container = document.getElementById('root');
if (!container) {
  throw new Error('Failed to load root container');
}
const root = createRoot(container);
root.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </ChakraProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
