import { Flex, Heading, Image, Text } from '@chakra-ui/react';

const NotFoundPage = () => {
  return (
    <div>
      <Flex
        height="64px"
        paddingX="24px"
        justifyContent="space-between"
        alignItems="center"
      >
        <a href="https://goresponsive.com/">
          <Image
            src="responsive-logo.svg"
            alt="Responsive Logo"
            paddingTop="0.5rem"
            opacity="1"
            transition="opacity 0.3s ease-in-out"
            _hover={{ opacity: '0.7' }}
          />
        </a>
      </Flex>

      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="90vh"
        className="titles-Appdream"
      >
        <Flex position="relative" maxWidth="400px">
          <Image src="/our-work-yellow-splash.svg" alt="Yellow splash" />
          <p className="error-text titles-Appdream">404</p>
        </Flex>

        {/* ADD TO DIV BELOW
          background: -webkit-linear-gradient(left, #2f1b3e -60.84%, #295494 111.76%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent; */}
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxWidth="600px"
          gap="30px"
        >
          <Heading
            as="h1"
            textAlign="center"
            color="#295494"
            fontSize="46px"
            fontWeight="500"
            textTransform="none"
          >
            Page not found
          </Heading>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Text as="p" fontSize="24px" textAlign="center" color="#295494">
              Oops! The page you are looking for does not exist. It might have
              been moved or deleted.
            </Text>
          </Flex>
        </Flex>
        <button className="back-button btn-styles">
          <a href="https://goresponsive.com/">Go back</a>
        </button>
      </Flex>
    </div>
  );
};

export default NotFoundPage;
