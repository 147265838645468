import { cloneDeep } from 'lodash';

export function formatPrice(
  cents,
  showCurrencyPrefix = true,
  currency = 'ZAR'
) {
  const defaultCurrencyOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };
  return (cents / 100)
    .toLocaleString(
      'en-ZA',
      showCurrencyPrefix
        ? {
            ...defaultCurrencyOptions,
            style: 'currency',
            currency,
          }
        : defaultCurrencyOptions
    )
    .replaceAll(',', ' ');
}

export const longestString = (arr) => {
  const copy = cloneDeep(arr);
  return copy
    .sort(
      (a, b) =>
        formatPrice(b.cost).toString().length -
        formatPrice(a.cost).toString().length
    )[0]
    .cost.toString().length;
};

export const getTimeZone = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return tz === 'Africa/Johannesburg';
};
