import { FC } from 'react';
import { ISection } from '../interfaces/sections.interface';
import { IFeature } from '../interfaces/features.interface';
import { ISelection } from '../interfaces/selection.interface';
import FeatureSection from './FeatureSection';
import ProjectResources from './ProjectResources';
import { IProjectResource } from '../interfaces/project-resource.interface';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';

export interface ISelectedSectionProps {
  sections: { [key: string]: ISection };
  features: { [key: string]: IFeature };
  selection: { [key: string]: ISelection };
  projectResources: { [key: string]: IProjectResource };
  baseDays: number;
  totalPrice: number;
  addToSelection: any;
  updateCalc: any;
  resetCalc: any;
}

const SelectedSection: FC<ISelectedSectionProps> = (props) => {
  const {
    sections,
    selection,
    features,
    projectResources,
    baseDays,
    totalPrice,
    addToSelection,
    updateCalc,
    resetCalc,
  } = props;
  const selectionIds = Object.keys(selection);
  const getSelectedSections = () => {
    const temp: any = {};
    Object.keys(selection).forEach((section: any) => {
      temp[section] = sections[section];

      temp[section].features = temp[section].features.map((feat: any) => {
        const selectedFeature = selection[section].features.find(
          (featId) => featId === feat.id
        );

        feat.selected = selectedFeature ? true : false;

        return feat;
      });
    });
    return temp;
  };
  let days = 0;
  // calculate the total days
  selectionIds.forEach((id) => {
    selection[id].features.forEach((feature) => {
      if (!features[feature].design) {
        days += features[feature].days;
        const daysCalc = days;
        return daysCalc;
      }
    });
  });

  return (
    <Box width="100%" padding="3rem" backgroundColor="#fff">
      {Object.keys(getSelectedSections()).map((key: any, index) => (
        <FeatureSection
          key={key}
          selectedFeatures={getSelectedSections()[key].features}
          features={features}
          sections={sections}
          index={index}
          sectionTitle={sections[key].name}
          addToSelection={addToSelection}
          updateCalc={updateCalc}
        />
      ))}
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginTop="2.5rem"
      >
        <Text fontWeight="700">Total Base Days</Text>
        <Box marginBottom="0" padding="0.5rem" border="1px solid #231f20">
          <Heading
            as="h3"
            fontWeight="700"
            width="3rem"
            outline="2px solid transparent"
            outlineOffset="2px"
            fontSize="1rem"
          >
            {days}
          </Heading>
        </Box>
      </Flex>
      <ProjectResources
        features={features}
        selection={selection}
        projectResources={projectResources}
        baseDays={baseDays}
        totalPrice={totalPrice}
        resetCalc={resetCalc}
        updateCalc={updateCalc}
      />
    </Box>
  );
};
export default SelectedSection;
