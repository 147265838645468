import { FC } from 'react';
import TypeSelector from './TypeSelector';
import { Box, Flex } from '@chakra-ui/react';
import DotMatrixSVG from '../assets/dot-matrix.svg';
export interface IHeaderProps {
  mobile: boolean;
  toggleMobile: any;
}
/*
const background = "side-graphic-right.svg";

var sectionStyle = {
  backgroundImage: `url(${background})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right top",
};
*/

const Header: FC<IHeaderProps> = (props) => {
  const { mobile, toggleMobile } = props;

  return (
    <Flex
      alignContent="center"
      flexDirection="column"
      paddingTop="100px"
      paddingX="16px"
      width="100%"
      position="relative"
      backgroundImage={DotMatrixSVG}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
    >
      <h1 className="text-center mb-medium">estimate my app</h1>
      <h2 className="text-center mb-standard max-w-h2 mx-auto head-font">
        Select the items below which best describe your app and the features you
        require.
      </h2>
      <p className="text-center mb-medium max-w-p  mx-auto">
        All estimates are approximate but should give you a rough idea of what
        it will take to build your app. But first, please select whether you
        require a web based app or mobile app.
      </p>
      <Box marginX="auto">
        <TypeSelector mobile={mobile} toggleMobile={toggleMobile} />
      </Box>
    </Flex>
  );
};

export default Header;
