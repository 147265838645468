import React, { FC, Suspense } from 'react';
import { ISection } from '../interfaces/sections.interface';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';

interface Props {
  index: number;
  section: ISection;
  addToSelection: any;
  selectedFeatures: string[];
  isLoading: boolean;
}

const Feature = React.lazy(() => import('./Feature'));

const Section: FC<Props> = (props) => {
  const { id, name, features, multipleFeatures } = props.section;

  const appendRequiredSectionTitle = (name: string) => {
    const indexOfAsterisk = name.indexOf('*');

    if (name.includes('*')) {
      const newTitle = name.substring(0, indexOfAsterisk);
      return (
        <span>
          {newTitle}
          <span style={{ color: 'red' }}>*</span>
        </span>
      );
    }
    return name;
  };

  let featuresLoaded;
  const featuredSection = props.index === 0 || props.index === 1;

  if (features?.length) {
    featuresLoaded = (
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="flex-start"
        gap="60px"
      >
        {features?.map((feature) => (
          <Feature
            small={!featuredSection}
            key={feature.id}
            section={id}
            feature={feature}
            multipleFeatures={multipleFeatures}
            addToSelection={props.addToSelection}
            selected={props.selectedFeatures.includes(feature.id)}
            isLoading={props.isLoading}
          />
        ))}
      </Flex>
    );
  } else {
    featuresLoaded = (
      <Text as="p" textAlign="center" color="#dc2626">
        No Features loaded
      </Text>
    );
  }

  /*
  const ovalStyle = {
    height: '520px',
    top: '-200px',
    left: '-20px',
    zIndex: -1,
    backgroundImage: `url(side-graphic-middle1.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left'
  };
  
  const rectStyle = {
    height: '313px',
    top: '-40px',
    left: '-20px',
    zIndex: -1,
    backgroundImage: `url(side-graphic-middle2.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left'
  };
  
  const rectEndStyle = {
    height: '313px',
    top: '-150px',
    right: '0px',
    zIndex: -1,
    backgroundImage: `url(side-graphic-end.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right'
  };
  */

  return (
    <Box
      position="relative"
      _odd={{ backgroundColor: 'rgba(35, 31, 32, 0.05)' }}
    >
      {props.index === 3 && (
        <div
          className="flex content-center flex-col pt-big w-full absolute"
          // style={rectStyle}
        ></div>
      )}
      {props.index === 3 && (
        <div
          className="flex content-center flex-col pt-big w-full absolute"
          //style={ovalStyle}
        ></div>
      )}
      {props.index === 9 && (
        <div
          className="flex content-center flex-col pt-big w-full absolute"
          //style={rectEndStyle}
        ></div>
      )}
      <Box
        paddingY={{ base: '2rem', md: '100px' }}
        paddingX="1rem"
        maxWidth="1200px"
        marginX="auto"
      >
        <Heading
          as="h2"
          textAlign="center"
          fontWeight="700"
          textTransform="uppercase"
          color="#231f20"
          marginBottom="60px"
        >
          {props.index + 1}. {appendRequiredSectionTitle(name)}
        </Heading>
        <Suspense
          fallback={
            <Text as="p" textAlign="center">
              Loading Features ...
            </Text>
          }
        >
          {featuresLoaded}
        </Suspense>
      </Box>
    </Box>
  );
};

export default React.memo(Section);
