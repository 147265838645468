import { Box, Flex, Image } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const ResponsiveLoader = () => {
  const [version, setVersion] = useState<number>(0);

  useEffect(() => {
    const updateImageVersion = () => {
      setVersion(version + 1);
    };

    setInterval(() => updateImageVersion(), 5000);
  });

  return (
    <Flex
      flexDirection="column"
      zIndex="50"
      alignItems="center"
      justifyContent="center"
      width="576px"
    >
      <Box>
        <Box>
          <Image src={`loader-fasterer.gif?v=${version}`} alt="Responsive" />
        </Box>
        <Box>
          <Box
            color="#295494"
            textAlign="center"
            fontWeight="700"
            fontSize={{ base: '1.2rem', lg: '2rem' }}
          >
            Make your customers love you
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default ResponsiveLoader;
