import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import Header from './Header';
import Section from './Section';
import Breakdown from './Breakdown';
import ResponsiveLoader from './ResponsiveLoader';
import { ISelection } from '../interfaces/selection.interface';
import { IFeature } from '../interfaces/features.interface';
import { ISection } from '../interfaces/sections.interface';
import { IProjectResource } from '../interfaces/project-resource.interface';
import { CalcData } from '../interfaces/types';
import { Type } from '../utils/enums';
import { cloneDeep, pickBy } from 'lodash';
import {
  fetchAllContent,
  getFeatures,
  getProjectResources,
  getSections,
} from '../services/flamelink.service';
import { Flex } from '@chakra-ui/react';
import DotMatrixSVG from '../assets/dot-matrix.svg';

const App: React.FC = () => {
  const [mobile, setMobile] = useState<boolean>(false);
  const [allSections, setAllSections] = useState<{
    [key: string]: ISection;
  }>({});
  const [sections, setSections] = useState<{ [key: string]: ISection }>({});
  const [features, setFeatures] = useState<{ [key: string]: IFeature }>({});
  const [selection, setSelection] = useState<{ [key: string]: ISelection }>({});
  const [projectResources, setProjectResources] = useState<{
    [key: string]: IProjectResource;
  }>({});
  const [baseDays, setBaseDays] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    const loadInitialData = async () => {
      const allContent = fetchAllContent();

      filterSections();

      await Promise.all([allContent]).then((values) => {
        const content: any = values[0];

        const sections = getSections(content);
        const features = getFeatures(content);
        const projectResources = getProjectResources(content);

        setAllSections(sections);
        setFeatures(features);
        setProjectResources(projectResources);

        setIsLoading(false);
        setIsDataLoaded(true);
      });
    };

    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => filterSections(), [mobile, isDataLoaded]);

  const addToSelection = (
    section: string,
    feature: string,
    allowMultiple: boolean
  ) => {
    const currentSelection = { ...selection };
    const tempSection = { ...currentSelection[section] };

    if (features[feature].baseDays) {
      setBaseDays(features[feature].days);
    }

    if (Object.keys(tempSection).length) {
      let featuresList = [];
      if (allowMultiple) {
        featuresList = [...tempSection.features];

        if (featuresList.includes(feature)) {
          featuresList.splice(featuresList.indexOf(feature), 1);
        } else {
          featuresList.push(feature);
        }
      } else {
        featuresList.push(feature);
      }
      if (featuresList.length) {
        currentSelection[section] = { features: featuresList };
      } else {
        delete currentSelection[section];
      }
    } else {
      const featuresList = [];
      featuresList.push(feature);

      currentSelection[section] = { features: featuresList };
    }

    setSelection(currentSelection);
  };

  const updateCalc = (data: CalcData, type: string) => {
    let temp;

    switch (type) {
      case Type.feature:
        temp = { ...features };
        temp[data.featureId].days = data.days;
        setFeatures(temp);
        break;

      case Type.resourceRate:
      case Type.resourceCount:
        temp = { ...projectResources };
        const allocation = temp[data.featureId].allocation.find(
          (item) => item.uniqueKey === data.allocationId
        );

        if (allocation) {
          allocation.percentage = data.percent;
        }

        setProjectResources(temp);
        break;

      default:
        console.error(`Unsupported type: ${type}`);
    }
  };

  const resetCalc = async () => {
    const clonedFeatures = cloneDeep(features);
    const clonedResources = cloneDeep(projectResources);

    setFeatures(clonedFeatures);
    setProjectResources(clonedResources);
  };

  const toggleMobile = () => {
    setMobile(!mobile);
  };

  const filterSections = () => {
    if (mobile) {
      const mobileSections = pickBy(
        allSections,
        (item) => item.mobileApplication
      );
      setSections(mobileSections);
    } else {
      const selectionCopy = cloneDeep(selection);

      const mobileOnlySelections = Object.keys(
        pickBy(allSections, (item) => !item.webApplication)
      ).filter((section) => selection[section]);

      if (mobileOnlySelections.length > 0) {
        mobileOnlySelections.forEach((section) => {
          delete selectionCopy[section];
        });
      }

      const webSections = pickBy(allSections, (item) => item.webApplication);
      setSections(webSections);
      setSelection(selectionCopy);
    }
  };

  return (
    <div className="main-container h-screen">
      {isLoading ? (
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          height={'100vh'}
          backgroundImage={DotMatrixSVG}
          backgroundSize={'cover'}
          backgroundRepeat={'no-repeat'}
        >
          <ResponsiveLoader />
        </Flex>
      ) : (
        <>
          <AppBar
            position="fixed"
            className="bg-white shadow-none border-b-2 border-light-grey"
          >
            <Toolbar className="h-toolbar">
              <div className="flex flex-row justify-between w-full items-center">
                <div className="">
                  <a
                    href="https://goresponsive.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="pt-2 logo-img"
                      src="responsive-logo.svg"
                      alt="Responsive Logo"
                    />
                  </a>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <div className="flex content-center">
            <Header mobile={mobile} toggleMobile={toggleMobile} />
          </div>
          <div className="flex content-center">
            <div className="w-full mt-6">
              {Object.keys(sections).map((key, index) => {
                const primarySectionKey = Object.keys(sections)[0];
                if (
                  key !== primarySectionKey &&
                  !selection?.[primarySectionKey]?.features?.length
                ) {
                  return null;
                }
                return (
                  <Section
                    key={key}
                    index={index}
                    section={sections[key]}
                    addToSelection={addToSelection}
                    selectedFeatures={selection?.[key]?.features || []}
                    isLoading={false}
                  />
                );
              })}

              <div>
                <Breakdown
                  sections={sections}
                  features={features}
                  selection={selection}
                  baseDays={baseDays ?? 0}
                  projectResources={projectResources}
                  addToSelection={addToSelection}
                  updateCalc={updateCalc}
                  resetCalc={resetCalc}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default App;
