import { FC } from 'react';
import { Flex, Box, Image } from '@chakra-ui/react';

export interface ITypeSelectorProps {
  mobile: boolean;
  toggleMobile: any;
}

const TypeSelector: FC<ITypeSelectorProps> = (props) => {
  const { mobile, toggleMobile } = props;
  const webIcon = 'web-icon.svg';
  const mobileIcon = 'mobile-icon.svg';

  return (
    <Flex flexDirection={{ base: 'column', lg: 'row' }}>
      <Box width="300px" textAlign="center">
        <Image
          src={webIcon}
          alt="Web"
          marginX="auto"
          height="42px"
          marginBottom="14px"
        />
        <Box
          fontWeight="700"
          borderStyle="solid"
          borderWidth="3px"
          borderColor="#295494"
          paddingY="14px"
          marginBottom="20px"
          cursor="pointer"
          bgColor={`${!mobile && '#295494'}`}
          color={`${!mobile && '#fff'}`}
          onClick={toggleMobile}
        >
          WEB
        </Box>
        <p className="px-medium">A web app or a back-end to a mobile app</p>
      </Box>

      <Box
        width="300px"
        textAlign="center"
        marginTop={{ base: '16px', lg: '0px' }}
      >
        <Image
          src={mobileIcon}
          alt="Mobile"
          marginX="auto"
          height="42px"
          marginBottom="14px"
        />
        <Box
          fontWeight="700"
          borderStyle="solid"
          borderWidth="3px"
          borderColor="#295494"
          paddingY="14px"
          marginBottom="20px"
          cursor="pointer"
          bgColor={`${mobile && '#295494'}`}
          color={`${mobile && '#fff'}`}
          onClick={toggleMobile}
        >
          MOBILE
        </Box>
        <p className="px-medium">A mobile app for iOS or Android</p>
      </Box>
    </Flex>
  );
};

export default TypeSelector;
