import { FC } from 'react';
import { IFeature } from '../interfaces/features.interface';
import { ISection } from '../interfaces/sections.interface';
import CheckBox from './CheckBox';
import { Type } from '../utils/enums';
// import { Flex, Heading } from '@chakra-ui/react';
import { Flex, Heading } from '@chakra-ui/react';

export interface IFeatureSectionProps {
  sectionTitle: string;
  features: { [key: string]: IFeature };
  sections: { [key: string]: ISection };
  selectedFeatures: IFeature[];
  index: number;
  addToSelection: any;
  updateCalc: any;
}

const FeatureSection: FC<IFeatureSectionProps> = ({
  sectionTitle,
  features,
  sections,
  selectedFeatures,
  index,
  addToSelection,
  updateCalc,
}) => {
  const updateSelection = (feature: IFeature) => {
    const featureId = feature.id;
    const section =
      Object.keys(sections).find((id) =>
        sections[id].features.map((feat) => feat.id).includes(featureId)
      ) || '';
    const multiple = sections[section].multipleFeatures;

    addToSelection(section, featureId, multiple);
  };

  const handleUpdate = (featureId: string, event: any) => {
    const newDays = event.target.value ? parseInt(event.target.value, 10) : 0;
    const data = { featureId, days: newDays };
    updateCalc(data, Type.feature);
  };

  return (
    <div>
      <Flex justifyContent="space-between" paddingTop="2rem">
        <Heading as="h3" fontSize="1rem" fontWeight="700" marginBottom="0.5rem">
          {index + 1}. {sectionTitle}
        </Heading>
        <Heading as="h3" fontSize="1rem" fontWeight="500" marginBottom="0.5rem">
          Base Days
        </Heading>
      </Flex>
      {selectedFeatures.map((feature: IFeature, i) => (
        <Flex
          key={feature.id}
          justifyContent="space-between"
          paddingY="0.5rem"
          borderTop="1px solid #b4b4b4"
        >
          <Flex alignItems="center" padding="0.5rem">
            <CheckBox
              checked={feature.selected || false}
              handleClick={() => updateSelection(feature)}
            />
            <Heading as="h3" fontSize="1rem" fontWeight="400">
              {feature.name}
            </Heading>
          </Flex>
          <Heading
            as="h3"
            fontSize="1rem"
            fontWeight="500"
            marginBottom="0"
            padding="0.5rem"
            border="1px solid #231f20"
          >
            <input
              className={`w-12 outline-none text-right ${
                feature.selected ? 'font-bold' : ''
              }`}
              type="number"
              min="0"
              value={features[feature.id].days}
              onChange={(e) => handleUpdate(feature.id, e)}
            />
          </Heading>
        </Flex>
      ))}
    </div>
  );
};

export default FeatureSection;
